.dialog-classname {
    font-family: "Salesforce Sans", sans-serif;
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 20px;
}
.expandedRowStyle {
    border-bottom: 1px solid;
}

.expandedRowStyle .expandedRowStyle1 {
    display: inline-block;
}

.rowStyle {
    margin-left: 0%;
    padding-bottom: 1%;
    padding-top: 1%;
}
.buttonStyle{
    margin-right: 1%;
}      
.slds-button_outline-brand{
   border-color: rgba(128, 128, 128, 0.349); 
}  
.eachRow {
    border-bottom: 1px solid grey;
    cursor: pointer;
}

.highlightRow {
    background-color: #f2f2f2;
    cursor: pointer;
}

.slds-accordion__section {
    padding-top: var(--sds-c-accordion-section-spacing-block-start, .75rem);
    padding-right: var(--sds-c-accordion-section-spacing-inline-end, .75rem);
    padding-bottom: var(--sds-c-accordion-section-spacing-block-end, .75rem);
    padding-left: 0px;
}

.slds-grid .slds-gutters .slds-col .address {
    display: block;
}

.deleteicon {
    width: 15px;
    cursor: pointer;
}

.expandicon {
    width: 14px;
}

.collapseicon {
    width: 14px;
}

.editicon {
    width: 13px;
}

.NestHeader {
    font-size: 16px;
    font-weight: 600;
    margin-left: 1%;
    margin-right: 0.5%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.childCount {
    font-size: 16px;
    height: 2%;
    margin-top: 1%;
    margin-right: 1%;
}

.checkboxStyle {
    width: 3%;
    margin-top: 1%;
}

.address {
    margin-left: 5.5%;
}

.slds-col {
    padding-right: 0%;
    padding-left: 0%;
}

.addressLink {
    margin-left: 2%;
}

.address {
    transition: 1s;
}

.nestDiv {
    border-bottom: 1px solid grey;
    cursor: pointer;
}

.topBorder {

    border-bottom: 1px solid grey;
}

.nameListDiv {
    margin-bottom: 2%;
}

.collapseStyle {
    margin-top: 0.7%;
}

.expandStyle {
    margin-top: 1%;
}

.editDivStyle {
    margin-top: 1%;
}

/*.childCheckboxDiv {
    width: 2.5%;
}*/

.firstNameDiv {
    width: 26%;
    word-break: break-all;
}

.lastNameDiv {
    width: 26%;
    word-break: break-all;
}

.phoneDiv {
    width: 17%;
    word-break: break-all;
}

.DataStyle {
    width: 90%;
    margin-left: 1%;
}

.emailStyle {
    margin-left: -5%;
}
.emailDiv {
    width: 23%;
    word-break: break-all;
}

.deleteDiv {
    margin-left: 8%;
    width: 2%;
}

.errorTitleDiv{
    margin-bottom: 1%;
    color: #ea001e;
}

.errorStyle{
    color: #ea001e;
}

.errorCountperNest {
    margin-top: 1%;
    margin-left: 2%;
}

.errorIconStyle{
    width: 1rem;
    height: 1rem;
    margin-top: -18%;
}
.errorIconNestStyle{
    width: 1rem;
    height: 1rem;
    margin-top: 1%;
}
.errorIconDiv{
    width: 2.5%;
}
.errorIconForNestStyle{
    width: 10%;
    margin-top: 41%;
    
}
.errorCountForNestStyle{
    margin-left: 70%;
    width: 2%;
    margin-top: 41%;
    color:  #ea001e;
    font-size: 1rem;
}
.nestErrorDiv{
    margin-left: 1rem;
}

.button{
    padding-left: 0px;
    padding-right: 0px;
    line-height:0%;
    text-align: center;
    vertical-align: middle;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: border .15s linear;
    transition: border .15s linear;
    border-color: white;
}

.InnerDiv{
    padding-left:0rem;
}

.tooltipCustom{
        font-family: "Salesforce Sans", sans-serif;
        font-size: 13px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 20px;
        text-align : left;
        max-height : 14rem;
        max-width : 100rem;
}

.dialog-classname{
    font-family: "Salesforce Sans", sans-serif;
    font-size: 13px!important;
    color: #FFFFFF!important;
    letter-spacing: 0!important;
    line-height: 20px!important;
    text-align : center !important;
    max-height : 14rem!important;
    max-width : 100rem !important;
    white-space: nowrap !important;
    display: inline-block !important;
}

.streetDiv{
    max-width: 15%;
    word-break: break-all;
}

.cityDiv{
    margin-left: 0.25%;
    max-width: 15%;
    word-break: break-all;
}

.stateDiv{
    margin-left: 0.25%;
    max-width: 15%;
    word-break: break-all;
}

.zipDiv{
    margin-left: 0.25%;
    max-width: 15%;
    word-break: break-all;
}
