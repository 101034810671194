.rowC{
    display:flex;
    flex-direction:row;
    padding-bottom: 2em;
}

.button-width {
    width : 90%;
}

.button-width-1 {
    margin-left : 5%;
}

.panel {
    padding-left: 0em;
    padding-right:0em;
}

.completedbackground {
    /*background-image: url(../BlueStripes.png);*/
    /* Full height */
    height: 100%; 
    width: 100%; 
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50rem;
}

.nest {
    background: #FFFFFF;
border: 1px solid #DDDBDA;
box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
border-radius: 4px;
	padding: 20px;
	transition: 200ms;
    max-width: 600px;
    height: 300px;
    margin-left:31%;
}   



.nest-header {
	display: flex;
	flex-wrap: nowrap;
    align-items: center;
    

}

.profile {
	width: 2.5rem;
	height: 2.5rem;
	background-color: tomato;
	border-radius: 50%;
	margin: 10px;
	font-size: 20pt;
	text-align: center;
	font-weight: bold;
	color: white;
	justify-content: center;
}

.letter {
	vertical-align: middle;
}

.nest-title-group {
    justify-content: center;
}

.nest-title {
	flex: 0.5;
	font-size: large;
	margin-bottom: 0;
    margin-top: 0;
    font-family: "Salesforce Sans", sans-serif;
    font-size: 30px;
    color: #00396B;
    letter-spacing: 0;
    line-height: 46px;
    text-align:center;
    font-weight: bold;    

}
.nest-date {
    flex: 0.3;
	font-size: small;
    margin-top: 0;
    padding:0rem 5rem;
}
.buttonstyle{

}


.nest-text {
    text-align:center;
}

.nest-left-margin {
    margin-left : 5%;
}

.scrollbar {
    overflow : 'hidden'
}