.headerline1{
    color:#032d60;
    font-weight: bold;
}

.namebackground{
    background-color: white;
}

.footerbackground{
    background-color:rgb(245, 243, 243);
    width: 100%;
     height: 70px;
}
.dropdowncss{
    width:100% !important;

}
.slds-dropdown__item>a {
    padding-top: 0rem;
    padding-bottom: 0rem;
}
.grpimage{
    width:25px;
    margin-right:9px;
}

.errorToastMessage{
    background-color: #D22B2B;
}

.successToastMessage{
    width: 16rem;
    min-width: 15rem;
}

.slds-button:disabled, .slds-button[disabled] {
    background-color: transparent;
    border-color: transparent;
    color: lightgrey;
    cursor: default;
}