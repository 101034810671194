.dialog-classname1{
    font-family: "Salesforce Sans", sans-serif;
    font-size: 13px!important;
    color: #FFFFFF!important;
    letter-spacing: 0!important;
    line-height: 20px!important;
    text-align : left!important;
    max-height : 15rem!important;
    max-width : 17rem!important;
}
.what-is-p200 {
    font-size: 33px;
    font-weight: 500;
    color: #032d60;
    width: 100%;
    text-align: left;
    margin-left: 4%;
    
}
.panel {
    width: 80%;
    height: 85%;
    background: white;
    margin-left: 9%;
    margin-top: 5%;
}
.sub-div-header {
    height: 32em;
    float: left;
}
.step-1 {
    font-size: 1.125rem;
    font-family: "Salesforce Sans", sans-serif;
    color: gray;
}
.group-icon {
    margin-top: 7%;
    margin-bottom: 5%;
}
.define-span {
    font-weight: 600;
    font-size: 25px;
    color: #505050;
}
.nest-1 {
    font-size: 16px;
    margin-left: 15%;
    text-align: left;
    margin-top: 5%;
}
.nest-2 {
    font-size: 16px;
    margin-left: 15%;
    text-align: left;
    margin-top: 7%;
}
.left-margin {
    margin-left: 15%;
}
.pdf-button {
    font-size: 9px;
    width: 100%;
    height: 45px;
    margin-bottom: 5%;
}
.step-2 {
    font-family: "Salesforce Sans", sans-serif;
    font-size: 1.125rem;
    color: gray;
    margin-left: -25%;
}
.step-2-margin {
    margin-top: 5%;
    margin-left: -25%;
}
.iconStyle .slds-button__icon {
    fill:#0176d3;
    width:  1.5rem;
    height: 1.5rem;
}
.contentSytle{
    white-space: pre-wrap;
}
.add-consumers {
    font-weight: 600;
    font-size: 25px;
    margin-left: -25%;
    color: #505050;
}
.add-consumers-2 {
    font-size: 16px;
    width: 70%;
    margin-left: 10%;
    text-align: left;
    margin-top: 5%;
}
.get-started {
    width: 40%;
    font-size: 16px;
    height: 35px;
    margin-top : 140px;
}
.tool-tip {
    color : blue;
}
