
.completedbackground {
    /*background-image: url(../BlueStripes.png);*/
    /* Full height */
    height: 100%; 
    width: 100%; 
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50rem;
}

.card {
    background: #FFFFFF;
border: 1px solid #DDDBDA;
box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
border-radius: 4px;
	padding: 20px;
	transition: 200ms;
    max-width: 600px;
    height: 300px;
    margin-left:31%;
}   



.card-header {
	display: flex;
	flex-wrap: nowrap;
    align-items: center;
    

}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.profile {
	width: 2.5rem;
	height: 2.5rem;
	background-color: tomato;
	border-radius: 50%;
	margin: 10px;
	font-size: 20pt;
	text-align: center;
	font-weight: bold;
	color: white;
	justify-content: center;
}

.letter {
	vertical-align: middle;
}

.card-title-group {
	justify-content: center;
}

.card-title {
	flex: 0.5;
	font-size: large;
	margin-bottom: 0;
    margin-top: 0;
    font-family: "Salesforce Sans", sans-serif;
    font-size: 25px;
    color: #00396B;
    letter-spacing: 0;
    line-height: 46px;
    text-align:center;
    font-weight: bold;    

}
.card-date {
    flex: 0.3;
	font-size: small;
    margin-top: 0;
    padding:0rem 5rem;
}
.buttonstyle{

}


.card-text {
    text-align:center;
}