.App {
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App .Panel {
  display: inline-block;
}

.Panel{
  background: white;
  height: 800px;
  width: 32% ;
  overflow: hidden;
}

.App .Panel .slds-tabs_card .slds-x-small-buttons_horizontal .subDivBody{
  display: inline-block;
}

.App .Panel .subDivHeader{
  display: inline-block;
}
.App .Panel .subDiv{
  display: inline-block;
}

.subDivHeader{
  width: 50%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pStyle {
  width: 80%;
  text-align: initial;
  font-size: 16px;
  margin-left: 9%;
  margin-top: 5%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.styleB{
  font-size: 16px;
  text-align: left;
  width: 80%;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

