.panel {
    padding-left: 0em;
    padding-right: 0em;
}
.verticalpane{
    background:#FFFFFF 100%;
	overflow:hidden !important;
}
.inputStyle{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}